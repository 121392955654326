//
// This file holds various Extensions to the javascript String object needed in the rest of the app
//

//
// OUTSIDE of a Module, thus, global
//
interface String
{
  /**
   * String Format method:
   *  Usage:
   *        let myStr = "this is an {0} for {0} purposes: {1}";
   *        alert(myStr.format("example", "end"));
   */
  format(...replacements: any[]): string;

  /**
   * String Contains method
   */
  contains(search: string): boolean;

  /**
   * String Replace All method
   */
  replaceAll(search: string, replacement: string): string;

  replaceAt(index: number, fromString: string, toString: string): string;

  startsWith(str: string): boolean;

  endsWith(str: string): boolean;

  converteStringToBase64(): string;
}

//
// This is a simple javascript implementation of string.format().
//
if (!String.prototype.format)
{
  String.prototype.format = function ()
  {
    let args = arguments;
    return this.replace(/{(\d+)}/g, function (match: any, number: number)
    {
      return typeof args[number] !== 'undefined'
        ? args[number]
        : match
      ;
    });
  };
}

//
// This is a simple javascript implementation of string.contains(s).
//
if (!String.prototype.contains)
{
  String.prototype.contains = function (search)
  {
    if (this.indexOf(search, 0) == -1) { return false; }
    else { return true; }
  };
}

//
// This is a simple javascript implementation of string.replaceAll(search, replacement).
//
if (!String.prototype.replaceAll)
{
  String.prototype.replaceAll = function (search, replacement)
  {
    let target = this;
    return target.split(search).join(replacement);
  };
}

if (!String.prototype.startsWith)
{
  String.prototype.startsWith = function (str: string)
  {
    return str.length > 0 && this.substring(0, str.length) === str;
  };
}

if (!String.prototype.endsWith)
{
  String.prototype.endsWith = function (str: string)
  {
    return str.length > 0 && this.substring(this.length - str.length, this.length) === str;
  };
}

if (!String.prototype.replaceAt)
{
  String.prototype.replaceAt = function (index, fromString, toString)
  {
    let hasWrongParams = typeof index !== 'number'
      || !fromString || typeof fromString !== 'string'
      || !toString || typeof toString !== 'string';
    if (hasWrongParams) return '';
    let fromIndex = index;
    let toIndex = index + fromString.length;
    return this.substr(0, fromIndex) + toString + this.substr(toIndex);
  };
}

if (!String.prototype.converteStringToBase64)
{
  String.prototype.converteStringToBase64 = function ()
  {
    return btoa(this);
  };
}

